<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="Purple Scribe Sessions">
      <b-btn
        v-if="$can('Create', 'Scribe')"
        class="float-right"
        style="margin-top: -10px"
        variant="success"
        @click="showScribeModal('modal-create-scribe-job')"
      >
        Create Scribe Session
      </b-btn>
      <b-card-text>
        This page shows all Purple Scribe sessions and their status.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-3">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Search session title or unique ID..."
              @keydown.enter="getMoreJobs(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreJobs(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-2">
          <label>Status</label>
          <b-form-select
            v-model="filters.status"
            class="mb-2"
            @change="getMoreJobs(1)"
          >
            <b-form-select-option value="">All</b-form-select-option>
            <b-form-select-option value="Awaiting">
              Awaiting File
            </b-form-select-option>
            <b-form-select-option value="Processing">
              Processing
            </b-form-select-option>
            <b-form-select-option value="Complete">
              Complete
            </b-form-select-option>
            <b-form-select-option value="Failed">Failed</b-form-select-option>
          </b-form-select>
        </section>

        <section class="col-2">
          <label>Session type</label>
          <b-form-select
            v-model="filters.job_type"
            class="mb-2"
            @change="getMoreJobs(1)"
          >
            <b-form-select-option value="">All</b-form-select-option>
            <b-form-select-option
              v-for="jobType in jobTypes"
              :key="jobType.id"
              :value="jobType.name"
            >
              {{ jobType.display_name }}
            </b-form-select-option>
          </b-form-select>
        </section>

        <section v-if="$can('ReadAll', 'Scribe')" class="col-2">
          <label>Created by</label>
          <user-search-select :selected="selectedUser" @change="updateSelectedUser" />
        </section>

        <div class="ui-spacer" />

        <section class="col-2 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="jobs"
          :per-page="0"
          class="data-table"
          hover
        >
          <template #cell(job_type)="data">
            <p v-if="data.item.job_type === 'AssuranceAllianceActionPlan'">
              CSAP
            </p>
            <p v-else>
              {{ data.item.job_type | splitCamelCase | capEachWord }}
            </p>
          </template>
          <template #cell(unique_id)="data">
            <!-- Supports old version of meta data-->
            <span v-if="data.item.meta.prison_number">
              {{ data.item.meta.prison_number }}
            </span>
            <span v-else>
              {{ data.item.meta.unique_identifier }}
            </span>
          </template>
          <template #cell(meta.recorded_at)="data">
            {{ data.item.meta.recorded_at | formatDateTime }}
          </template>
          <template v-if="$can('Read', 'Scribe')" #cell(actions)="data">
            <router-link
              :to="{
                name: 'purple-scribe-details',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              View session
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="total_count"
              class="my-0"
              @change="getMoreJobs"
            />
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal
      id="modal-create-scribe-job"
      hide-footer
      no-close-on-backdrop
      title="Create a scribe session"
    >
      <CreateScribeJobModal @close="closeModals" @refresh="refreshScribeJobs" />
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import { mapState } from "vuex";
import PurpleScribeService from "@/services/PurpleScribeService";
import CreateScribeJobModal from "@/views/purpleScribe/sections/CreateScribeJobModal.vue";
import UserSearchSelect from "@/components/input/PaginatedSearchSelect/UserSearchSelect.vue";

export default {
  name: "Activities",
  components: {
    UserSearchSelect,
    CreateScribeJobModal,
  },
  props: {},
  data() {
    return {
      headers: [
        {
          key: "title",
          label: "Title",
        },
        {
          key: "unique_id",
          label: "Unique ID",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "meta.recorded_at",
          label: "Recorded On",
        },
        {
          key: "job_type",
          label: "Session Type",
        },
        {
          key: "user.name",
          label: "User",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      jobs: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        search: "",
        status: "",
        job_type: "",
        user_uid: "",
      },
      total_count: 0,
      timeout: null,
      jobTypes: [],
      selectedUser: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
  },
  mounted() {
    this.getJobTypes();
    this.getJobs();
  },
  methods: {
    async getJobTypes() {
      try {
        const res = await PurpleScribeService.getJobTypes();
        this.jobTypes = res.data;
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not get Purple Scribe job types, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    getMoreJobs(page) {
      this.filters.page = page - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getJobs();
    },
    async getJobs() {
      try {
        let res = await PurpleScribeService.list(this.filters);
        // Loop over and convert meta from json string if it is present
        this.jobs = res.data.data.map(j => {
          let metaJson = {
            prison_number: "",
            case_worker_name: "",
            prisoner_name: "",
            recorded_at: "",
          };
          try {
            metaJson = JSON.parse(j.meta);
          } catch (_) {}

          return {
            ...j,
            meta: metaJson,
          };
        });
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not get Purple Scribe sessions, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 15,
        search: "",
        status: "",
        job_type: "",
        user_uid: "",
      };
      this.selectedUser = null;

      this.getJobs();
    },
    showScribeModal(modalName) {
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide("modal-create-scribe-job");
    },
    refreshScribeJobs() {
      this.closeModals();
      this.getMoreJobs(1);
    },
    updateSelectedUser(user) {
      this.selectedUser = user;
      if (!this.selectedUser) {
        this.filters.user_uid = ""
      } else {
        this.filters.user_uid = this.selectedUser.uid;
      }
      this.getMoreJobs(1)
    }
  },
};
</script>

<style lang="scss"></style>
