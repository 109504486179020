<template>
  <div class="update-scribe-job-modal">
    <section v-if="!loading">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit(createScribeJob)">
          <b-form-group>
            <!-- Title -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Title</label>
              <validation-provider
                v-slot="validationContext"
                name="title"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.title"
                  placeholder="Enter session title..."
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Job Type -->
            <label>Session Type</label>
            <b-form-select v-model="jobType" class="mb-1">
              <b-form-select-option
                v-for="jt in jobTypes"
                :key="jt.id"
                :value="jt.name"
              >
                {{ jt.display_name }}
              </b-form-select-option>
            </b-form-select>

            <!-- Session Initiator -->
            <b-input-group class="mb-1 d-flex flex-column">
              <label>Session Initiator</label>
              <validation-provider
                v-slot="validationContext"
                name="session initiator"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.session_initiator"
                  placeholder="Enter name of person who created the session..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Attendants -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Attendants</label>
              <validation-provider
                v-slot="validationContext"
                name="attendants"
                :rules="{ required:true }"
              >
                <b-form-textarea
                  v-model="job.third_parties"
                  placeholder="Alex M, John D, Jane D"
                  :state="getValidationState(validationContext)"
                />
                <label>Enter attendants' names separated by commas</label>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>

            </b-input-group>

            <!-- Unique Identifier -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Unique Identifier</label>
              <validation-provider
                v-slot="validationContext"
                name="unique identifier"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.unique_identifier"
                  placeholder="Enter unique ID (NOMIS / NHS Number / Case Ref)"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- File Upload -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>File</label>
              <validation-provider
                v-slot="validationContext"
                name="File"
                :rules="{ required:true, isAudioFile }"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Choose a video/audio file or drag it here..."
                  drop-placeholder="Drop file here..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Recorded At -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Recorded At</label>
              <validation-provider
                v-slot="validationContext"
                name="recorded at"
                :rules="{ required:true, isInPast }"
              >
                <b-form-input
                  v-model="job.recorded_at"
                  placeholder="Enter recorded at date..."
                  type="datetime-local"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <b-input-group class="d-flex flex-row-reverse mt-3">
              <b-btn variant="primary" type="submit" :disabled="invalid">Create Scribe Session</b-btn>
              <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
            </b-input-group>
          </b-form-group>
        </b-form>
      </validation-observer>
    </section>
    <section v-else>
      <div class=" d-flex justify-content-center p-5">
        <b-spinner variant="primary" label="Loading devices" />
      </div>
    </section>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import PurpleScribeService from "@/services/PurpleScribeService";
import { isInPast, isAudioFile } from '@core/utils/validations/validations';
import {mapState} from "vuex";

export default {
  name: 'CreateScribeJobModal',
  components: {},
  data() {
    return {
      job: {
        title: '',
        session_initiator: '',
        third_parties: '',
        unique_identifier: '',
        recorded_at: '',
      },
      file: null,
      uploadVersion: 2,
      versions: {
        high_accuracy: 2,
        standard: 1
      },
      jobType: null,
      jobTypes: [],
      jobTypeFilters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
    isInPast() {
      return isInPast
    },
    isAudioFile() {
      return isAudioFile
    },
  },
  mounted() {
    this.getJobTypes();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async getJobTypes() {
      this.loading = true;
      try {
        const res = await PurpleScribeService.getJobTypes();
        this.jobTypes = res.data;
        this.jobType = this.jobTypes[0].name;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not get job types. Please refresh and try again."
        );
      } finally {
        this.loading = false;
      }
    },
    async createScribeJob() {
      const meta = {
          session_initiator: this.job.session_initiator.trim(),
          third_parties: this.job.third_parties.split(",").map(tp => tp.trim()),
          unique_identifier: this.job.unique_identifier.trim(),
          recorded_at: this.job.recorded_at
      };
      const scribeJobDto = {
          title: this.job.title,
          meta: JSON.stringify(meta),
          version: parseInt(this.uploadVersion),
          job_type: this.jobType,
          origin: 'MdmPortal'
      }
      try {
        this.loading = true
        const { data } = await PurpleScribeService.create(scribeJobDto)
        this.uploadVersion === this.versions.standard  && await PurpleScribeService.upload(data.id, this.file)
        this.uploadVersion === this.versions.high_accuracy  && await PurpleScribeService.uploadV2(data.id, this.file)
        helperService.showNotfySuccess(this.$toast, 'The scribe session has been successfully created.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while creating the scribe session. Please try again.')
      } finally {
        this.loading = false
        this.$emit('refresh');
      }
    },
  },
}
</script>

<style lang="scss">
